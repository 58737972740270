@import "../../../styles/breakpoints.scss";

.header_container {
    background-color: var(--color-primary-dark);
    position: fixed;
    overflow: hidden;
    top: 0;
    width: 100%;
    z-index: 2;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 4vh;

    @include lg {
        overflow: hidden;
        position: relative;
        padding: 0 2.4vh;
    }

    .wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 8vh;
        gap: 12px;

        &.complete {
            height: 9vh;
        }

        .header_logo {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            gap: 2vh;

            .decorator {
                width: 0.3vh;
                background-color: var(--color-secondary-light);
                transition: width 0.2s ease-out;
                padding: 1.9vh 0;
            }

            label {
                color: var(--color-white);
                font-size: 2vh;
                font-weight: 600;
            }
        }

        a {
            color: var(--color-white);
            font-weight: 400;
            position: relative;

            @include lg {
                padding: 14px 16px;
                text-decoration: none;
                font-size: 17px;
                display: block;
            }

            &.login_link {
                height: fit-content;
            }

            .decorator_wrapper {
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: -1px;
                height: 2px;
                width: 100%;

                .decorator {
                    height: 100%;
                    width: 0%;
                    background-color: var(--color-secondary-light);
                    transition: width 0.2s ease-out;
                }
            }

            &:hover {
                .decorator_wrapper {
                    .decorator {
                        width: 100%;
                    }
                }
            }

            .icon {
                background: black;
                display: block;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .menu {
            display: flex;
            align-items: center;
            gap: 32px;
        }

        .cat {
            display: flex;
            gap: 16px;
            align-items: center;
            
            button {
                padding: 10px 30px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                height: 48px;
                color: var(--color-white);
                background-color: var(--color-secondary-light);
                border: 0;

                @include lg {
                    padding: 5px 30px;
                    margin-top: 6px;
                    font-size: 14px;
                    font-weight: 800;
                    width: 150px;
                }
            }
        }

        .logout_wrapper {
            position: relative;

            button {
                background-color: var(--color-primary-dark);
                border: 0;
                color: var(--color-white);
                font-size: 20px;
                font-weight: 600;
                display: flex;
                align-items: center;
                gap: 8px;

                svg {
                    path {
                        fill: var(--color-white);
                    }
                }

                p {
                    span {
                        color: var(--color-secondary-light);
                    }
                }

            }
        }

        
    }
}

.menu_wrapper {
    position: absolute;
    display: none;
    background-color: var(--color-white);
    color: var(--color-primary-dark);
    border: 1px solid var(--color-border-gray);
    border-radius: 5px;
    padding: 12px 0;
    width: max-content;
    z-index: 10;
    top: 7vh;
    left: 90%;
    transform: translateX(-20%);

    &.active {
        display: block;
    }

    button {
        display: flex;
        gap: 12px;
        width: 100%;
        font-size: 16px;
        background-color: var(--color-white);
        color: var(--color-primary-dark);
        padding: 8px 12px;
        border: none;

        &:hover {
            background-color: var(--color-hovered-item);
        }

    }
}

.menu_wrapper_mobile {
    display: flex;
    max-height: 100vh;
    flex-direction: column;
    gap: 20px;
    padding-top: 60px;
    padding-bottom: 955PX;

    p {
        color: var(--color-white);
        padding: 1px 16px;
        text-decoration: none;
        text-align: center;
        font-size: 18px;
        display: block;
    }
}

.error_modal {
    background-color: var(--color-white);
    padding: 40px;
    border-radius: 8px;
    width: 40%;
    position: fixed;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);

    @include lg {
        width: 75%;
    }
    
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .content_title {
        display: flex;
        justify-content: space-between;

        img {
            cursor: pointer;

            &:hover {
                background-color: var(--color-btn-hover);
                border-radius: 2px;
            }
        }

        .title {
            font-size: 24px;
            font-weight: 700;
            color: var(--color-purple-dark);
        }
    }

    .bold {
        font-size: 16px;
        font-weight: 600;
    }

    .content_buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .buttons {
            display: flex;
            justify-content: flex-end;
            gap: 32px;
            width: fit-content;
        }
    }

    .content_buttons_centred {
        display: flex;
        justify-content: center;
        width: 100%;

        .buttons {
            display: flex;
            justify-content: center;
            gap: 32px;
            width: fit-content;
        }
    }

    button {
        flex: 1;
        color: var(--color-white);
        font-size: 16px;
        font-weight: 700;
        background-color: var(--color-secondary-light);
        border: 0;
        padding: 15px 40px;
        border-radius: 8px;

        &.confirm {
            &:hover {
                background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
            }
        }

        &.cancel {
            flex: none;
            border: 2px solid var(--color-purple-dark);
            color: var(--color-purple-dark);
            background-color: var(--color-white);

            &:hover {
                background-color: var(--color-hovered-item);
            }
        }
    }
}