.navigation {
    background-color: var(--color-secondary);
    display: flex;
    align-items: center;
    gap: 0.4vh;
    font-size: 14px;
    font-weight: 500;
    padding: 20px 15px;

    .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.4vh;
        color: var(--color-white);
        font-weight: 500;

        &_full {
            margin-left: 3%;
        }

        svg {
            transform: rotate(270deg);

            path {
                fill: var(--color-white);
            }
        }
    }

    .link {
        &:hover {
            text-decoration: underline;
        }

        label {
            cursor: pointer;
        }
    }

    .lastone {
        color: var(--color-white);
        font-weight: 800;
    }
}