@import "../../../../styles/breakpoints.scss";

.container {
    display: flex;
    border-radius: 12px;
    border: 1px solid var(--color-primary);
    background: linear-gradient(to right,  var(--color-white) 0%,var(--color-white) 75%,var(--color-gray) 75%,var(--color-gray) 100%);
    padding: 0 16px; 

    @include lg {
        padding: 0 8px;
    }

    input {
        outline: none;
        border: none;
        font-size: 16px;
        color: var(--color-primary);
        font-size: 14px;
        font-weight: 400;
        height: 48px;
        padding-right: 16px;
        width: 75%;

        &::placeholder {
            color: var(--color-primary);
        }
    }

    &:hover {
        background: linear-gradient(to right,  var(--color-white) 0%,var(--color-white) 75%,var(--color-btn-hover) 75%,var(--color-btn-hover) 100%);
    }

    .button {
        align-items: center;
        background-color: var(--color-gray);
        cursor: pointer;
        display: flex;
        font-size: 16px;
        gap: 16px;
        justify-content: center;
        width: 25%;

        img {
            height: 24px;
            width: 24px;
        }

        label {
            @include lg {
                display: none;
            }
        }

        &:hover {
            background-color: var(--color-btn-hover);
        }
    }
}

.container_select {
    cursor: pointer;
    display: flex;
    border-radius: 8px;
    border: 1px solid var(--color-primary);
    background: var(--color-white);
    color: var(--color-primary);
    justify-content: space-between;
    padding: 12px 16px;
    position: relative;

    label {
        cursor: pointer;
    }

    @include lg {
        padding: 0 8px;
    }

    &_label {
        display: flex;
        align-items: center;
        gap: 10px;

        .font_bold {
            font-weight: 700;
        }
    }

    .counter {
        background-color: var(--color-secondary);
        border-radius: 500px;
        padding: 2px 10px;
        font-size: 14px;
        font-weight: 600;
    }

    &:hover {
        background: var(--color-btn-hover);
    }

    &_options {
        background: var(--color-white);
        border: 1px solid var(--color-primary);
        border-radius: 5px;
        color: var(--color-primary);
        display: flex;
        flex-direction: column;
        max-height: 305px;
        overflow-y: overlay;
        position: absolute;
        z-index: 1;
        padding: 16px 0;
        width: max-content;
        min-width: 150px;

        @include lg {
            padding: 0 8px;
        }

        &_option {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 4px;
            min-height: 2rem;
            margin: 0 8px;
    
            &:hover {
                background: var(--color-table-row-selected);
                border-radius: 5px;
            }
        }
    
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 0.5rem;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-secondary-light);
            border: solid 6px transparent;
            border-radius: 0.5rem;
        }
    }
}

.container_selected {
    background-color: var(--color-blue2);
    color: var(--color-white);

    &_close {
        filter: invert(50%) sepia(49%) saturate(2032%) hue-rotate(340deg) brightness(102%) contrast(95%);
        margin: 2px;
    }

    &_arrow {
        filter: invert(100%) sepia(0%) saturate(7413%) hue-rotate(174deg) brightness(122%) contrast(99%);
    }

    &_rotate {
        transform: rotate(180deg);
    }

    &:hover {
        background: var(--color-blue2);
    }
}

.container_selected_secondary {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    padding: 8px 18px;

    &_arrow {
        filter: brightness(0) saturate(100%) invert(96%) sepia(98%) saturate(2%) hue-rotate(329deg) brightness(111%) contrast(100%);
    }

    &:hover {
        background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
    }
}