.notification {
    position: absolute;
    display: flex;
    justify-content: center;

    .icon_message {
        display: flex;
        align-items: center;
        justify-items: center;
        gap: 16px
    }

    .notification_wrapper {
        padding: 12px 24px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 60px;
        display: flex;
        opacity: 0%;
        background: var(--color-notification-success);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }

    .notification_wrapper_warning {
        padding: 16px 18px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 22px;
        display: flex;
        opacity: 0%;
        background: var(--color-notification-warning);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }
}