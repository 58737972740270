.checkup {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .title {
        font-size: 24px;
        font-weight: 600;
    }

    .section {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &_bar {
            display: flex;
            align-items: center;
            gap: 24px;
        }

        &_subtitle {
            display: flex;
            align-items: center;
            gap: 8px;

            label {
                color: var(--color-purple-dark);
                font-size: 20px;
                font-weight: 600;

                .size18 {
                    font-size: 18;
                }
            }
        }

        .question {
            font-size: 16px;
            font-weight: 400;
        }

        textarea {
            resize: none;
            border: 1px solid var(--color-border-gray);
            padding: 16px;
            box-sizing: border-box;
            border-radius: 8px;
            width: 100%;
        }

        .comment {
            display: flex;
            flex-direction: column;
            gap: 24px;

            label {
                font-weight: 400;
    
                &.subtitle_left {
                    font-weight: 400;
                    text-align: end;
                }
            }

            .sublabel {
                font-weight: 600;
            }
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .button_orange {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                cursor: pointer;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 700;
                gap: 8px;
                height: max-content;
                background-color: var(--color-secondary);
                color: var(--color-white);
                padding: 10px 80px;

                img {
                    filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(7481%) hue-rotate(108deg) brightness(106%) contrast(96%);
                }

                &:hover {
                    background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
                }
            }
        }
    }

    .line {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 45%;

        span {
            text-align: left;
            width: 50%;
        }

        .desc {
            border-radius: 10px;
            text-align: center;
            padding: 8px;
            min-width: 200px;

            &_clasif {
                --brackgroundColor: #C1C1C1;
                --fontcolor: #525252;
                background: var(--brackgroundColor);
                color: var(--fontcolor);
                
                &_blue {
                    background: var(--color-blue3);
                    color: var(--color-white);
                }
            }
    
            &_risk {
                --brackgroundColor: #36B551;
                background: var(--brackgroundColor);
                color: var(--color-white);

                &_yellow {
                    --backgroundColor: #FCD265;
                    background: var(--backgroundColor);
                }

                &_red {
                    --backgroundColor: #FF5151;
                    background: var(--backgroundColor);
                }
            }
        }
    }

    .table {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .row {
            display: flex;
            border-radius: 10px;
            gap: 32px;
            text-align: center;
        }

        .header {
            background-color: var(--color-blue2);
            color: var(--color-white);
            padding: 14px 28px;
            font-size: 16px;
            font-weight: 700;
        }

        .detail {
            display: flex;
            flex-direction: column;

            .row {

                &_backgray {
                    background-color: var(--color-selector-back);
                    padding: 25px;
                    border-radius: 10px;
                }
            }

            .comment {
                text-align: justify;
            }

            .short {
                height: 110px;
                display: flex;
                align-items: center;
            }
        }

        .column {

            &_main {
                width: 30%;
                text-align: left;
            }

            &_short {
                width: 20%;
            }

            &_detail {
                width: 100%;
            }
        }

        .bar {
            display: flex;
            flex-direction: column;
        }
    }

    .label {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
    }


    .title_orange {
        color: var(--color-secondary);
        font-size: 20px;
        font-weight: 600;
    }

    .rotate180 {
        transform: rotate(180deg);
    }

    .gap4 {
        gap: 4px;
    }

    .gap8 {
        gap: 8px;
    }

    .gap16 {
        gap: 16px;
    }
}