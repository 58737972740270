@import "../../../styles/breakpoints.scss";

.notification {
    position: fixed;
    top: 12.5vh;
    left: 40%;
    display: flex;
    justify-content: center;
    z-index: 5;

    @include lg {
        position: fixed;
        top: 11.5vh;
        display: flex;
        width: 90%;
    }

    .notification_wrapper {
        padding: 1.2vh 2.4vh;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 12px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(168.33deg, #4898FB -10.69%, #4898FB -10.67%, #5C2EFC 162.15%);
        transition: opacity 1s;

        @include lg {
            padding: 1.6vh;
            font-size: 14px;
        }

        &.active {
            opacity: 100%;
        }
    }

    .notification_wrapper_warning {
        padding: 16px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 15px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(168.33deg, #FA7D61 -10.69%, #FA7D61 -10.67%, #D34A2C 162.15%);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }

    .notification_wrapper_error {
        padding: 16px;
        font-weight: 600;
        border-radius: 10px;
        color: var(--color-white);
        align-items: center;
        gap: 15px;
        display: flex;
        opacity: 0%;
        background: linear-gradient(#FA7D61 -10.69%, #D34A2C 74.83%);
        transition: opacity 1s;

        &.active {
            opacity: 100%;
        }
    }
}