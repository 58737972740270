@import '../../../styles/breakpoints.scss';

.home_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    position: relative;

    .menu {
        background-color: var(--color-primary-dark);
        overflow-y: scroll;
        margin-top: 8vh;
        display: flex;
        flex-direction: row;
        width: 20%;
        padding: 3.6vh 0;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 0;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            border: solid 6px transparent;
            border-radius: 1rem;
            box-shadow: inset 0 0 10px 10px var(--color-secondary-light);
        }

        @include lg {
            display: none;
        }
    }

    .button_menu_hidden {
        background-color: var(--color-primary-dark);
        border-radius: 0 21.026px 21.026px 0;
        cursor: pointer;
        width: 3vh;
        height: 21.8vh;
        position: absolute;
        top: 40%;
        left: 0%;
        z-index: 2;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            &.svg {
                transform: rotate(180deg);

                path {
                    fill: var(--color-primary-dark);
                }
            }
        }
    }

    .content {
        background-color: var(--color-white);
        margin-top: 8vh;
        display: flex;
        flex-direction: column;
        width: 100%;

        &_scroll {
            overflow: scroll;
            scrollbar-width: none;
            display: flex;

            &::-webkit-scrollbar {
                background-color: transparent;
                width: 0;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
            }
        }
    }
}

.loading_container {
    background-color: rgba(48, 48, 48, 0.45);
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    width: 100%;
    z-index: 1;

    .spinner_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35vh;
        height: 100%;

        &_short {
            margin-left: -20%;
        }

        p {
            font-size: 30px;
            font-weight: 600;
            color: var(--color-white);
        }
    
        .spinner {
            border: 5px solid var(--color-white);
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border-left-color: var(--color-blue2);
          
            animation: spin 1s ease infinite;
        }
          
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            
            100% {
                transform: rotate(360deg);
            }
        }

    }
}