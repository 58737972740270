:root {
    --color-background: #FCFDFE;
    --color-primary: #3F5073;
    --color-primary-dark: #091026;
    --color-secondary: #F86F35;
    --color-secondary-light: #fa7d61;
    --color-white: #FFF;
    --color-gray: #F0F3F7;
    --color-blue: #0111A2;
    --color-blue2: #0C1137;
    --color-blue3: #3B43FB;
    --color-blue-ligth: #9EA8FF;
    --color-blue-ligth2: #F7F4FE;
    --color-border-gray: #A5A5A5;
    --color-border-gray2: #737576;
    --swiper-theme-color: #007aff;
    --color-purple-dark: #3f46ad;
    --color-footer-swiper: #7A99FF;
    --color-error-ligth: #FF2E3A;
    --color-error: #FF2E3A;
    --color-footer-icon: #FAFAFA;
    --color-password-icon: #575DB7;
    --color-table-row-selected: #EAF7FF;
    --color-hovered-item: #EBFAFF;
    --color-steper-mobile: #D9D9D9;
    --color-select-blocked: #D4D4D4;
    --color-back-button: #001330;
    --color-table-hover: #F6DACF;
    --color-table-odd: #F3F3F3;
    --color-label-hover: #FFF4F2;
    --color-btn-hover: #DEEDFF;
    --color-btn-feedback-new: #00194F;
    --color-scrollbar: #FA7D61;
    --color-start-title: #171E40;
    --color-modal-background: rgba(0, 0, 0, 0.70);
    --color-black-blue: #050D2A;
    --color-notification-success: #0F9043;
    --color-notification-warning: #E01B1B;
    --color-trash-hover: #FFD6DB;
    --color-trash-hover-2: #f0818e;
    --color-selector-back: #EFF3F8;
    --color-recruiment-gray: #BCBCBC;
    --color-recruiment-yellow: #FF9900;
    --color-green: #008A27;
    --color-review-subtitle: #3E3E3E;
}