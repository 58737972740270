@import "../../../styles/breakpoints.scss";

.feedback {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include lg {
        width: 100%;
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        gap: 10px;

        &_24px {
            font-size: 24px;
            font-weight: 600;
        }

        &_bar {
            background-color: var(--color-secondary);
            border-radius: 2.5px;
            width: 4px;
            height: 30px;

            &_thin_gray {
                background-color: var(--color-recruiment-gray);
                width: 2px;
            }
        }

        h2 {
            color: var(--color-blue);
        }

        &_rewiew {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            text-align: left;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .p_title {
        font-size: 16px;
        font-weight: 600;
    }

    .section {
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
            text-align: justify;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 32px;

            .pharagrap {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .e_title {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--color-review-subtitle);
                }

                .inline {
                    display: flex;
                    gap: 20px;
                    flex-wrap: wrap;

                    .e_item {
                        background: var(--color-purple-dark);
                        border-radius: 32px;
                        display: flex;
                        padding: 4px 15px;
                        align-items: flex-start;
                        font-size: 14px;
                        gap: 10px;
                        color: var(--color-white);
                        width: max-content;
                    }

                    .no_info {
                        font-weight: 400;
                        color: black;
                    }
                }
            }

            .review {
                display: flex;
                flex-direction: column;
                gap: 13px;

                .feedback {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                }

                .comments {
                    display: flex;
                    flex-direction: column;
                    gap: 13px;
                }
            }
        }

        &_review {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
        }

        &_main {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 12px;

            .button {
                cursor: pointer;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 700;
                gap: 8px;
                height: max-content;

                &_share {
                    color: var(--color-secondary);
                    padding: 5px;

                    &:hover {
                        background-color: var(--color-label-hover);
                    }
                }

                &_checkup {
                    background-color: var(--color-secondary);
                    color: var(--color-white);
                    padding: 10px;

                    img {
                        filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(7481%) hue-rotate(108deg) brightness(106%) contrast(96%);
                    }

                    &:hover {
                        background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
                    }
                }
            }
        }
    }

    .stars {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        img {
            height: 18px;
            width: 19px;
        }

        .selected {
            filter: invert(46%) sepia(73%) saturate(1224%) hue-rotate(340deg) brightness(103%) contrast(94%);
        }
    }

    .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 0 16px;

        &_line {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            div {
                background-color: var(--color-recruiment-gray);
                border-radius: 50%;
                height: 17px;
                width: 17px;
            }

            .circle {
                &_mini {
                    height: 4px;
                    width: 4px;
                }

                &_selected {
                    background-color: var(--color-secondary);
                }

                &_completed {
                    background-color: var(--color-green);
                }
            }
        }
    }

    .review_card {
        border: 2px solid var(--color-white);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;

        &:hover {
            border: 2px solid var(--color-secondary);
            border-radius: 21px;
            cursor: pointer;

            .arrow_view {
                filter: invert(59%) sepia(79%) saturate(3217%) hue-rotate(339deg) brightness(100%) contrast(95%);
            }
        }
    }
}