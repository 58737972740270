@import "../../../../styles/breakpoints.scss";

.loading_container {
    background: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 100;

    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        label {
            color: var(--color-white);
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            gap: 5px;
        }
    }
}