@import '../../../styles/breakpoints.scss';

.modal_wrapper {
    &.modal_active {
        padding-right: 18px;
        height: 100vh;
        overflow: hidden!important;
    }
}

.modal_wrapper_mobile {
    &.modal_active {
        height: 100vh;
        overflow: hidden!important;
    }
}

.error_modal {
    background-color: var(--color-white);
    padding: 32px;
    border-radius: 8px;
    
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        color: var(--color-blue2);
    }

    .buttons {
        display: flex;
        justify-content: flex-end;

        button {
            color: var(--color-white);
            font-weight: 600;
            background-color: var(--color-secondary-light);
            border: 0;
            padding: 12px;
            border-radius: 8px;
            width: 200px;
        }
    }
}

.error_modal_mobile {
    background-color: var(--color-white);
    padding: 24px 16px;
    border-radius: 8px;
    
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        color: var(--color-blue2);
    }

    p {
        font-size: 16px;
    }

    .buttons {
        display: flex;
        justify-content: center;

        button {
            color: var(--color-white);
            font-weight: 600;
            background-color: var(--color-secondary-light);
            border: 0;
            padding: 10px;
            border-radius: 8px;
            width: 244px;
        }
    }

}

.pass_recovery {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 7.35vh;

    .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
            &.title {
                color: var(--color-white);
                font-size: 40px;
                font-weight: 700;

                @include lg {
                    font-size: 24px;
                }
            }
        }

        .instructions {
            padding: 4vh 0;
            width: 55%;
            display: flex;
            flex-direction: column;
            gap: 24px;
            font-size: 24px;
            color: var(--color-white);

            @include lg {
                padding: 24px 0;
                gap: 16px;
                font-size: 18px;
                width: 100%;
            }
        }

        .recovery_form_wrapper {
            background-image: url(../../../assets/img/S_background.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 64px 0;

            @include lg {
                padding: 40px 0;
                padding: 3vh 2.4vh 15vh;
            }

            .image_column {
                width: 55%;
                position: relative;

                img {
                    display: block;
                    max-width: 699px;
                    max-height: 700px;
                    width: auto;
                    height: auto;
                    position: absolute;
                    right: -570px;
                    bottom: -65px;
                    z-index: -1;
                }
            }
        }

        .recovery_button {
            display: flex;
            justify-content: center;

            > button {
                width: 100%;

                &:hover {
                    background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
                }
            }
        }

        button {
            color: var(--color-purple-dark);
            font-weight: 600;
            background-color: var(--color-white);
            border: 1px solid var(--color-purple-dark);
            padding: 12px;
            border-radius: 8px;
            width: 300px;

            @include lg {
                width: 100%;
            }

            &.active {
                color: var(--color-white);
                background-color: var(--color-secondary-light);
                border: 0;

                &:hover {
                    background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
                }
            }

            &:hover {
                background-color: var(--color-blue-ligth2);
            }
        }

        form {
            background: var(--color-white);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 3.2vh;

            hr {
                width: 100%;
            }

            a {
                color: var(--color-secondary-light);
                font-weight: 700;
            }

            .centered_field {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: 700;
                padding: 0 3.7vh;

                .error_message {
                    font-weight: 400;
                }
            }

            .error_message {
                color: var(--color-error);
            }

            .form_field {
                display: flex;
                flex-direction: column;
                gap: 16px;

                &.short {
                    width: 515px;
                }

                label {
                    font-weight: 700;
                }

                input {
                    width: 100%;
                    height: 50px;
                    border: 1px solid var(--color-border-gray);
                    border-radius: 5px;
                    padding: 0 16px;
                    box-sizing: border-box;

                    &.error {
                        border-color: var(--color-error);
                    }
                }
            }

            &.password_recovery {
                border: 1px solid var(--color-border-gray);
                padding: 48px;
                margin-top: 24px;

                @include lg {
                    padding: 30px 20px;
                }
            }
        }

        .verification_wrapper {
            padding: 62px;

            @include lg {
                padding: 40px 10px;
            }

            p {
                &.title {
                    color: var(--color-blue2);
                }
            }
            
            .instructions_wrapper {
                padding-top: 3.2vh;
                padding-bottom: 4vh;
                display: flex;
                flex-direction: column;
                gap: 16px;
                position: relative;

                @include lg {
                    padding: 26px 0;
                }

                &.small_gap {
                    gap: 4px;
                }

                .password_validator {
                    flex-direction: column;
                    gap: 10px;
                    display: none;

                    p {
                        font-size: 15px;
                    }
                    
                    .validator_title {
                        font-weight: 600;
                    }

                    &.active {
                        display: flex;
                    }

                    .validator_row {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        &.validated {
                            svg {
                                circle {
                                    fill: var(--color-secondary-light);
                                }
                            }
                        }
                    }

                }
            }

            .resend_button {
                display: flex;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                background-color: var(--color-white);
                border: 0;
                padding: 0;
                color: var(--color-secondary);

                @include lg {
                    background-color: transparent;
                }
            }

            .buttons_wrapper {
                display: flex;
                gap: 32px;

                @include lg {
                    flex-direction: column-reverse;
                    align-items: center;
                    gap: 12px;
                }
            }

            .code_wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 16px;

                @include lg {
                    padding-bottom: 4px;
                }

                .code_label {
                    font-weight: 700;
                }

                .code_row {
                    display: flex;
                    gap: 12px;

                    @include lg {
                        gap: 6px;
                    }

                    input {
                        width: 70px;
                        height: 70px;
                        text-align: center;
                        font-size: 40px;
                        border-radius: 8px;
                        border: 1px solid var(--color-border-gray);

                        @include lg {
                            width: 15%;
                            height: 56px;
                            font-size: 24px;
                        }

                        &.error {
                            border-color: var(--color-error);
                        }

                    }
                }
            }

            p {
                &.error_message {
                    color: var(--color-error);
                }
            }
        }
    }
}

.pass_recovery_mobile {
    display: flex;
    position: relative;
    padding: 9vh 2.4vh;

    .wrapper {
        display: flex;
        flex-direction: column;

        p {
            &.title {
                color: var(--color-white);
                font-size: 24px;
                font-weight: 700;
            }
        }

        .instructions {
            display: flex;
            flex-direction: column;
            padding: 24px 0;
            gap: 16px;
            font-size: 18px;
            color: var(--color-white);
        }

        .recovery_form_wrapper {
            padding: 40px 0;
            background-image: url(../../../assets/img/S_background.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 3vh 2.4vh 15vh;
        }

        .recovery_button {
            display: flex;
            justify-content: center;

            > button {
                width: 100%;

                &:hover {
                    background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
            }
            }
        }

        button {
            color: var(--color-purple-dark);
            font-weight: 600;
            background-color: var(--color-white);
            border: 1px solid var(--color-purple-dark);
            padding: 12px;
            border-radius: 8px;
            width: 100%;

            &.active {
                color: var(--color-white);
                background-color: var(--color-secondary-light);
                border: 0;

                &:hover {
                    background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
                }
            }

            &:hover {
                background-color: var(--color-blue-ligth2);
            }
        }

        .verification_wrapper {
            padding: 40px 0;
            





            

            p {
                &.error_message {
                    color: var(--color-error);
                }
            }
        }
    }
}