// Small smartphones
//$screen-xs-max: 480px;

// Small tablets and large smartphones (landscape view)
//$screen-sm-min: 481px;
//$screen-sm-max: 768px;

// Small tablets (portrait view)
//$screen-md-min: 811px;
//$screen-md-max: 1024px;

// Tablets and small desktops
//$screen-lg-min: 769px;
$screen-lg-max: 1024px;

// Large tablets and desktops
$screen-xl-min: 1025px;

/** ----------------- MEDIA QUERIES --------------------- */

// Extra small devices
// @mixin xs {
//     @media only screen and (min-width: 0px) and (max-width: #{$screen-xs-max}) {
//         @content;
//     }
// }

// Small devices
// @mixin sm {
//     @media only screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
//         @content;
//     }
// }

// Medium devices
// @mixin md {
//     @media only screen and (min-width: #{$screen-md-min}) and (max-width :#{$screen-md-max}) {
//         @content;
//     }
// }

// Large devices
@mixin lg {
    @media only screen and (min-width: 0px) and (max-width: #{$screen-lg-max}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}