@import '../../../styles/breakpoints.scss';

.menu {
    padding-bottom: 9vh;
    color: var(--color-white);
    font-size: 14px;
    width: 100%;
    position: relative;

    .option {
        background-color: transparent;
        color: var(--color-white);
        cursor: pointer;
        border: none;
        outline: none;
        display: flex;
        flex-direction: column;
        padding: 1.2vh 3.6vh;
        transition: 0.4s;
        width: 100%;

        &.selected {
            background-color: var(--color-white);
            color: var(--color-secondary);

            path {
                fill: var(--color-secondary);
            }
        }

        &_item {
            &:hover {
                background-color: var(--color-white);
                color: var(--color-secondary);
                img {
                    color: var(--color-secondary);
                }
                svg {
                    path {
                        fill: var(--color-secondary);
                    }
                }
            }
        }

        &_container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 4.5vh;
            width: 100%;

            &_title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0.4vh;
            }

            &.opened {
                .svg {
                    transform: rotate(180deg);
                }
            }

            svg {
                path {
                    fill: var(--color-white);
                }
            }
        }
    }

    .item {
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 0.7vh 6vh;
        height: 3.4vh;

        &:hover {
            background-color: var(--color-white);
            color: var(--color-secondary);
        }

        &.selected {
            background-color: var(--color-white);
            color: var(--color-secondary);
        }
    }

    .button_menu_show {
        background-color: var(--color-white);
        border-radius: 21.026px 0 0 21.026px;
        cursor: pointer;
        width: 3vh;
        height: 21.8vh;
        position: absolute;
        top: 35%;
        right: 0;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            &.svg {
                transform: rotate(180deg);

                path {
                    fill: var(--color-primary-dark);
                }
            }
        }
    }
}