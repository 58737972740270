@import '../../../styles/breakpoints.scss';

// Table responsive style
// https://codepen.io/AllThingsSmitty/pen/MyqmdM

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4vh;
    position: relative;

    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
            color: var(--color-primary);
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .container_list_btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .btn_label {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1.7vh;
                width: 100%;

                label {
                    color: var(--color-primary);
                }

                .separator {
                    background-color: var(--color-border-gray2);
                    border-radius: 99px;
                    width: 0.25vh;
                    height: 4vh;
                }

                .reload {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.4vh;
                    padding: 2px;

                    label {
                        color: var(--color-secondary);
                        cursor: pointer;
                    }

                    &:hover {
                        background-color: var(--color-label-hover);
                    }
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                gap: 32px;

                button {
                    //width: 330px;
                    background-color: var(--color-secondary-light);
                    border: 0;
                    color: var(--color-white);
                    border-radius: 8px;
                    padding: 16px 0;
                    font-weight: 700;
                    font-size: 16px;
                    padding: 16px 12px;

                    &:hover {
                        background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
                    }

                    // &.cancel_button {
                    //     width: max-content;
                    //     min-width: 220px;
                    //     padding: 0 80px;
                    //     border: 2px solid var(--color-purple-dark);
                    //     background-color: var(--color-white);
                    //     color: var(--color-purple-dark);
                    // }
                }
            }
        }

        .container_list_sel {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
        }

        // Border rounder: https://unused-css.com/blog/css-rounded-table-corners/
        table {
            background: var(--color-white);
            border-spacing: 0;
            border-collapse: separate;
            width: 100%; // 1358px;
            overflow-y: scroll;

            tr {
                margin-bottom: .625em;
            }

            thead {
                background-color: var(--color-blue2);
                color: var(--color-white);

                @include lg {
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                th {
                    font-size: 16px;
                    padding: 15px 4px;

                    .suggestion {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 4px;
                        position: relative;

                        &_tooltip {
                            background: var(--color-white);
                            border: 1px solid var(--color-primary);
                            border-radius: 8px;
                            color: var(--color-primary);
                            font-size: 12px;
                            font-weight: 400;
                            font-style: normal;
                            display: flex;
                            justify-content: center;
                            gap: 8px;
                            top: 25px;
                            position: absolute;
                            padding: 16px;
                            width: 331px;
                            z-index: 1;

                            div {
                                height: 100%;
                            }

                            &_icon {
                                filter: invert(50%) sepia(49%) saturate(2032%) hue-rotate(340deg) brightness(102%) contrast(95%);
                            }

                            &_textbold {
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            tbody {
                color: var(--color-primary);
                box-shadow: 2px 5px 11.2px 0px rgba(140, 140, 140, 0.11);

                td {
                    font-size: 14px;
                    padding: 12px 4px;

                    @include lg {
                        text-align: center;
                        font-size: 16px;
                        font-weight: 700;
                    }

                    .photo {
                        border-radius: 50px;
                        height: 50px;
                        width: 50px;
                        object-fit: cover;

                        @include lg {
                            height: 100px;
                            width: 100px;
                        }
                    }

                    .photo_padding {
                        padding-right: 30px;

                        @include lg {
                            text-align: center;
                        }
                    }

                    .td_column {
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        label {
                            cursor: pointer;
                        }

                        .name {
                            font-weight: 500;
                        }

                        .font_secondary {
                            color: var(--color-secondary);
                        }
                    }

                    .review_container {
                        display: flex;
                        justify-content: center;
                        gap: 5px;
                    }

                    .fontgray {
                        color: var(--color-border-gray)
                    }

                    .fontwhite {
                        color: var(--color-white)
                    }

                    .border {
                        border-radius: 8px;
                        padding: 4px 8px;

                        &_green {
                            background-color: var(--color-notification-success);
                        }

                        &_gray {
                            background-color: var(--color-border-gray);
                        }
                    }
                    
                    @include lg {
                        display: block;
                        margin-bottom: .625em;
                    }

                    &:before {
                        @include lg {
                            content: attr(data-label);
                            float: left;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }

                    &:last-child {
                        @include lg {
                            border-bottom: 0;
                        }
                    }
                }

                tr:nth-child(even) {
                    background: var(--color-table-odd);
                    border-radius: 20px;
                }

                tr:nth-child(odd) {
                    background: var(--color-white)
                }

                tr:hover {
                    border-radius: 20px;
                    background: var(--color-table-hover);
                    cursor: pointer;
                }
            }

            // Border rounder: https://stackoverflow.com/questions/9873936/how-to-create-rounded-corners-on-table-head-only
            .first_child_border {
                border-radius:10px 0 0 10px;
                width: 5vh;
                padding-left: 2vh;

                &_row {
                    border-radius:20px 0 0 20px;
                }

                @include lg {
                    width: 90%;
                }
            }

            .last_child_border{
                border-radius:0 10px 10px 0;

                &_row {
                    border-radius:0 20px 20px 0;
                }

                @include lg {
                    display: none;
                }

                .content_center {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .trash {
                    width: fit-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        border-radius: 5px;
                        background-color: var(--color-trash-hover-2);
                    }
                }
            }
        }

        .resume {
            display: flex;
            flex-direction: row;

            .data {
                background-image: url(../../../assets/img/background_resume.png);
                background-size: 100% 100%;
                color: var(--color-white);
                display: flex;
                flex-direction: column;
                gap: 30px;
                justify-content: center;
                border: 1px solid;
                border-radius: 20px;
                margin-right: 25px;
                width: 25%;
                height: max-content;
                padding: 34px;
                text-align: center;

                .image {
                    width: 220px;
                    height: 220px;
                    margin: 0 auto;
                    position: relative;

                    .photo {
                        border: 4px solid var(--color-secondary);
                        border-radius: 50%;
                        height: 206px;
                        width: 206px;
                        object-fit: cover;
                    }

                    .button {
                        border-radius: 7px;
                        position: absolute;
                        top: 180px;
                        left: 30px;
                        right: 30px;
                        z-index: 2;

                        .container {
                            display: flex;
                            height: 35px;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;

                            .title {
                                background-color: var(--color-secondary);
                                display: grid;
                                align-items: center;
                                width: 20%;
                                border-top-left-radius: 7px;
                                border-bottom-left-radius: 7px;
                            }

                            .download {
                                background-color: var(--color-blue);
                                display: grid;
                                align-items: center;
                                cursor: pointer;
                                width: 80%;
                                border-top-right-radius: 7px;
                                border-bottom-right-radius: 7px;

                                &:hover {
                                    background-color: var(--color-secondary);
                                }
                            }
                        }

                    }
                }

                .name {
                    font-size: 18px;
                    font-weight: 700;
                }

                .position {
                    font-size: 18px;
                    font-weight: 400;
                }

                .feedback {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .stars{
                        display: flex;
                        justify-content: center;
                        gap: 4px;

                        .selected {
                            filter: invert(46%) sepia(73%) saturate(1224%) hue-rotate(340deg) brightness(103%) contrast(94%);
                        }
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 23px;

                    .container {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        gap: 19px;
                        padding: 0 18px;

                        .title {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: left;
                            gap: 4px;

                            label {
                                font-weight: 600;
                            }

                            span {
                                text-align: justify;
                                padding-right: 20px;
                            }
                        }
                    }

                    .separator {
                        background-color: #284C9A;
                        width: 100%;
                        height: 1px;
                    }
                }
            }

            .detail {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-left: 25px;
                width: 75%;

                .menu {
                    background-color: #EFF3F8;
                    border-radius: 40px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 4px;
                    justify-content: center;

                    span {
                        font-style: normal;
                        font-weight: 600;
                        padding: 10px 24px;
                        text-align: center;
                        width: 20%;
                    }

                    .selected {
                        background: var(--color-white);
                        border: 2px solid var(--color-blue);
                        border-radius: 40px;
                        padding: 10px 24px;
                    }
                }
            }
        }
    }
}

.not_found {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    gap: 8px;

    label {
        font-size: 24px;
        color: var(--color-primary);
        font-weight: 700;
    }

    .pharagrap {
        color: var(--color-primary);
        font-size: 20px;
        font-weight: 400;

        &_simple {
            margin-bottom: 30px;
        }

        &_multiple {
            line-height: 32px;
        }

        &_textbold {
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.box_shadow {
    box-shadow: 2px 5px 11.2px 0px rgba(140, 140, 140, 0.11);
}

.textleft {
    text-align: left;
}

.textcenter {
    text-align: center;
}

.textright {
    text-align: right;
}

.padding_x_10 {
    padding: 0 10px;
}

.padding_x_20 {
    padding: 0 20px;
}

.padding_y_16 {
    padding: 16px 0;
}

.padding_left_30 {
    padding-left: 30px;
}

.width_90 {
    width: 90%;
}

.width_40 {
    width: 40%;
}