@import "../../../styles/breakpoints.scss";

.back_button {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    color: var(--color-purple-dark);
    padding: 40px 0;
    gap: 10px;

    span {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
    }
}

.main {
    display: flex;
    width: 100%;
    justify-content: center;

    &_full {
        width: 100%;
    }

    @include lg {
        width: 100%;
    }
}

.flex_column_center {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.candidate_info {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .wrapper {
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include lg {
            width: 98%;
        }

        .error_message {
            color: var(--color-error);
            font-weight: 400!important;
            font-size: 14px;
        }

        .action_container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;

            img {
                cursor: pointer;
                border-radius: 2px;
                padding: 3px;
                
                &:hover {
                    border-radius: 5px;
                    background-color: var(--color-btn-hover)
                }
            }

            .trash {
                &:hover {
                    border-radius: 5px;
                    background-color: var(--color-trash-hover);
                }
            }
        }

        hr {
            border-top: 1px solid var(--swiper-theme-color);
            border-bottom: 0;
            margin: 12px 0;
        }

        .margin_long {
            margin: 40px 0;
        }

        p {
            &.title {
                color: var(--color-purple-dark);
                font-size: 28px;
                font-weight: 700;
            }

            &.form_section_title {
                font-weight: 700;
                font-size: 20px;
                padding: 32px 0;

                &.no_padding_top {
                    padding-top: 0;
                }
            }

            &.form_section_title_2 {
                font-weight: 700;
                font-size: 20px;
            }
        }

        button {
            &.add_info {
                background-color: var(--color-white);
                border: 0;
                padding: 1px;
                width: fit-content;
                display: flex;
                gap: 8px;
                justify-content: center;
                align-items: center;
                color: var(--color-secondary-light);
                font-size: 16px;
                font-weight: 700;

                &.padded {
                    padding-top: 32px;
                }

                &.idle {
                    padding-top: 32px;
                    color: var(--color-purple-dark);

                    svg {
                        path {
                            fill: var(--color-purple-dark);
                        }
                    }
                }

                &:hover {
                    background-color: var(--color-label-hover);
                }

            }

            &.blue {
                background-color: var(--color-primary-dark);
                border-color: transparent;
                border-radius: 10px;
                color: var(--color-white);
                display: flex;
                font-size: 16px;
                font-weight: 700;
                gap: 8px;
                padding: 8px;

                img {
                    filter: brightness(0) invert(1);
                }

                &:hover {
                    background-color: var(--color-secondary);
                }
            }
        }

        label {
            font-weight: 700;

            &.subtitle {
                font-weight: 400;
            }

            &.subtitle_left {
                font-weight: 400;
                text-align: end;
            }
        }

        .candidate_columns {
            display: flex;
            justify-content: center;
            height: 100%;
            gap: 24px;

            @include lg {
                justify-content: space-between;
                gap: 0;
            }

            .candidate_form {
                width: 80%;
                padding: 40px 0;

                @include lg {
                    width: 100%;
                    padding: 9vh 1vh;
                }

                .image_container {
                    width: 100%;
                    padding: 48px 0;
                    display: flex;
                    justify-content: center;
                    border: 1px solid var(--color-border-gray);
                    border-radius: 8px;
                    margin-top: 32px;
                    cursor: pointer;

                    button {
                        border: 0;
                        background-color: var(--color-white);
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .image_content {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: 24px;
    
                            .image_circle {
                                width: 150px;
                                height: 150px;
        
                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    object-fit: cover;
                                }
                            }
    
                            .image_text {
                                display: flex;
                                gap: 6px;
                                align-items: center;
                                justify-content: center;
                                font-size: 16px;
                                font-weight: 600;
                                color: var(--color-secondary-light);
                                padding: 8px 4px;
                                border-radius: 5px;
        
                                &:hover {
                                    background-color: var(--color-label-hover);
                                }
                            }
                        }
                    }

                    &.error {
                        border-color: var(--color-error-ligth);
                    }

                }

                .form_wrapper {
                    padding-top: 32px;

                    @include lg {
                        padding: 32px 0;
                    }
                }

                .form_container {

                    .label_blue {
                        color: var(--color-purple-dark);
                    }

                    .form_column_simple {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        margin-bottom: 40px;

                        .subtitle {
                            color: var(--color-purple-dark);
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }

                    .form_columns {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 36px;

                        .form_column {
                            width: 45%;
                            display: flex;
                            flex-direction: column;
                            justify-content: end;
                            gap: 8px;

                            button {
                                border: 1px solid var(--color-border-gray);
                                background-color: var(--color-white);
                                display: flex;
                                justify-content: space-between;
                                height: 50px;
                                border-radius: 5px;
                                padding: 0 16px;
                                align-items: center;
                                position: relative;

                                &.error {
                                    border-color: var(--color-error);
                                }
                            }
                        }
                    }

                    .form_columns_line {
                        display: flex;
                        padding-bottom: 8px;
                        gap: 40px;

                        &.top_padded {
                            padding-top: 40px;
                        }

                        &.top_padded_short {
                            padding-top: 12px;
                        }

                        .form_column {
                            width: 45%;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .form_column_line {
                                display: flex;
                                gap: 8px;

                                input, select {
                                    width: -webkit-fill-available;
                                }
                            }
                        }

                        .form_columns_line_2 {
                            display: flex;
                            padding-bottom: 8px;
                            gap: 8px;

                            img {
                                cursor: pointer;
                            }
                        }
                    }

                    .form_row {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        &.no_gap {
                            gap: 0px;
                        }

                        &.paragraph {
                            gap: 24px;
                            padding: 0 0 24px;
                        }

                        &.padded {
                            padding: 32px 0;
                        }

                        &.padded_short {
                            padding: 8px 0;
                        }
                        
                        &.top_padded {
                            padding-top: 32px;
                        }

                        &.top_padded_short {
                            padding-top: 8px;
                        }
                    }

                    input {
                        border-radius:  5px;
                        border: 1px solid var(--color-border-gray);
                        height: 50px;
                        padding: 0 16px;
                        box-sizing: border-box;

                        &.file_input {
                            display: none;
                        }

                        &.error {
                            border-color: var(--color-error);
                        }
                    }

                    select {
                        border-radius:  5px;
                        border: 1px solid var(--color-border-gray);
                        height: 50px;
                        padding: 0 16px;
                        box-sizing: border-box;
                    }

                    textarea {
                        resize: none;
                        border: 1px solid var(--color-border-gray);
                        padding: 16px;
                        box-sizing: border-box;
                        border-radius: 8px;
                        width: 100%;
                        height: 160px;
                    }
                }

                .buttons_spaced {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row-reverse;
                    padding-bottom: 4vh;

                    .trash {
                        color: var(--color-error);
                        cursor: pointer;
                        display: flex;
                        font-size: 16px;
                        font-weight: 700;
                        gap: 8px;
                        justify-content: center;
                        align-items: center;
                        padding: 4px;
                        height: fit-content;

                        &:hover {
                            border-radius: 5px;
                            background-color: var(--color-trash-hover);
                        }
                    }
                }

                .buttons {
                    display: flex;
                    justify-content: flex-end;
                    gap: 32px;

                    .continue_button {
                        width: 330px;
                        background-color: var(--color-secondary-light);
                        border: 0;
                        color: var(--color-white);
                        border-radius: 8px;
                        padding: 16px 0;
                        font-weight: 700;
                        font-size: 16px;
                        
                        &:hover {
                            background-image: linear-gradient(90deg, #FA7D61 1.56%, #FD4961 100%);
                        }
                    }

                    .cancel_button {
                        width: max-content;
                        min-width: 220px;
                        padding: 0 80px;
                        border: 2px solid var(--color-purple-dark);
                        border-radius: 8px;
                        background-color: var(--color-white);
                        color: var(--color-purple-dark);
                        font-weight: 700;
                        font-size: 16px;
                        
                        &:hover {
                            background-color: var(--color-hovered-item);
                        }
                    }
                }

            }
        }

        .candidate_resume {

            @include lg {
                margin-top: 2vh;
            }

            .mobile_separator {
                display: none;
                border-color: var(--color-secondary-light);
                border-width: 3px;

                @include lg {
                    display: block;
                }

            }

            .content_wrapper {
                padding: 0 24px;
            }

            .section_subtitle {
                margin-top: 24px;
            }

            .section_title {
                font-weight: 600;
                font-size: 20px;
                color: var(--color-purple-dark);
                display: flex;
                align-items: center;
                gap: 12px;

                .decorator {
                    height: 30px;
                    width: 5px;
                    background-color: var(--color-secondary-light);
                    border-radius: 2.5px
                }

                &.padded {
                    padding-bottom: 38px;
                }
                
            }

            .postulates_content {
                display: flex;
                flex-direction: column;
                gap: 32px;
                margin-top: 7px;
            }

            .personal_info {
                padding: 24px 0;
                width: 100%;
                display: flex;
                flex-direction: column;

                .selector {
                    background-color: var(--color-selector-back);
                    border-radius: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 20px;
                    width:fit-content;
                    margin-bottom: 40px;
                    font-size: 14px;
                    font-weight: 500;

                    &_pill {
                        cursor: pointer;
                        background-color: var(--color-selector-back);
                        padding: 8px 24px;

                        &_left {
                            border-radius:40px 0 0 40px;
                        }

                        &_rigth {
                            border-radius:0 40px 40px 0;
                        }
                    }

                    &_selected {
                        border: 2px solid;
                        border-radius: 10px;
                        background-color: var(--color-blue);
                        color: var(--color-white);
                        margin: 4px;
                    }
                }

                .section_main {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    // align-items: center;
                }

                .section_header {
                    display: flex;
                    align-items: center;
                    position: relative; 
                    gap: 12px;
                    padding-bottom: 12px;

                    @include lg {
                        width: 100%;
                    }

                    .button_container {
                        display: flex;
                        gap: 16px;
                    }

                    .vertical_separator {
                        height: 30px;
                        width: 2px;
                        background-color: var(--color-border-gray);
                    }

                    .label_status {
                        font-weight: 600;
                    }

                    .label_detail {
                        font-weight: 500;
                    }
                }

                &_wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 0 40px;

                    @include lg {
                        margin: 4px 0;
                    }

                    .candidate_contact_info_mobile {
                        width: 100%;
                        padding-bottom: 32px;
                        display: none;
                        gap: 16px;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        .contact_field {
                            display: flex;
                            gap: 6px;
                            align-items: center;
                        }

                        @include lg {
                            display: flex;
                        }

                    }

                    .about_candidate {
                        width: 100%;
                    }

                    .profile_info {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        gap: 24px;

                        .candidate_info_columns {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            p {
                                &.candidate_name {
                                    font-size: 24px;
                                    font-weight: 600;
                                }
                            }
                            
                            .candidate_contact_info {
                                display: flex;
                                gap: 12px;

                                .personal_info_separator {
                                    width: 2px;
                                    height: 25px;
                                    background-color: var(--color-border-gray);
                                }

                                .candidate_position {
                                    font-size: 18px;
                                    font-weight: 600;
                                }

                                .contact_field {
                                    display: flex;
                                    gap: 6px;
                                    align-items: center;
                                    font-size: 16px;
                                    font-weight: 400;
                                }

                                @include lg {
                                    display: none;
                                }
                            }
                        }
                    }

                    .info_column {
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                    }

                    .picture_column {
                        width: fit-content;
                        padding: 24px 0;

                        .candidate_profile_picture {
                            width: 160px;
                            height: 160px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }
                        
                        button {
                            padding: 12px 24px;
                            background-color: var(--color-white);
                            border: 0;
                        }
                    }
                }
            }
            
            .section {
                margin: 40px 0 32px 0;

                @include lg {
                    margin: 4px 0;
                }

                .cards_wrapper {
                    display: flex;
                    flex-direction: column;
                }

                .pills_wrapper {
                    display: flex;
                    gap: 12px;
                    padding: 24px 0 12px 0;
                    flex-wrap: wrap;

                    p {
                        padding: 8px 16px;
                        color: var(--color-white);
                        background-color: var(--color-purple-dark);
                        border-radius: 30px;
                    }
                }

                .button_container {
                    display: flex;
                    gap: 16px;
                }

                &_title {
                    color: var(--color-purple-dark);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                padding-top: 32px;

                button {
                    width: 330px;
                    background-color: var(--color-secondary-light);
                    border: 0;
                    color: var(--color-white);
                    border-radius: 8px;
                    padding: 16px 0;
                    font-weight: 700;
                    font-size: 16px;

                    @include lg {
                        width: 100%;
                    }
                }
            }
        }

        .info_card {
            border-radius: 8px;
            padding: 24px 0;
            display: flex;
            flex-direction: column;
            gap: 16px;

            @include lg {
                padding: 8px 0;
                gap: 0;
            }

            .language_info {

                > label {
                    display: inline;
                }

                > p {
                    display: inline;
                }
            }

            .card_title {
                display: flex;
                justify-content: flex-start;
                gap: 8px;

                @include lg {
                    flex-direction: column;

                    .hidden_mobile {
                        display: none;
                    }
                }

            }
        }
    }
}

.list_container {
    border-radius:  5px;
    border: 1px solid #B5B5B5;
    margin-top: 16px;
    margin-bottom: 16px;

    &.top_padded {
        margin-top: 40px;

        @include lg {
            margin-top: 30px;
        }
    }

    .list_items {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 32px;

        @include lg {
            gap: 4px;
            padding: 16px;
        }

        .item_grouped {
            display: flex;
            justify-content: space-between;
        }

        .certification_info {
            display: flex;
            flex-direction: column;
            gap: 0px;
            padding-top: 8px;

            .info_container {
                display: flex;
                gap: 8px;

                > label {
                    font-weight: 700;
                }
            }
        }
    }
}

.vl {
    background: #A5A5A5;
    width: 1px;
    height: 50px;
    margin-top: 8px;
}

.error_message_font {
    color: var(--color-error-ligth);
    font-weight: 400!important;
    font-size: 14px;
    padding-bottom: 8px;
}

.message_wrapper {
    position: relative;
    padding: 88px 0;

    .title_wrapper {
        width: 60%;
        text-align: left;
        font-size: 40px;
        font-weight: 700;

        p {
            background: linear-gradient(270deg, #7A99FF 0%, #5A3481 96.9%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .message_content {
        padding: 24px 0;
        font-size: 22px;
        width: 42%;

        .buttons {
            display: flex;
            gap: 32px;
            padding: 32px 0;

            button {
                width: 330px;
                background-color: var(--color-secondary-light);
                border: 0;
                color: var(--color-white);
                border-radius: 8px;
                padding: 16px 0;
                font-weight: 700;
                font-size: 16px;

                &.iddle {
                    background-color: var(--color-white);
                    color: var(--color-purple-dark);
                    border: 2px solid var(--color-purple-dark);
                }
            }
        }

        .links {
            .links_row {
                display: flex;
                gap: 16px;
                padding-top: 32px;

                .icon_container {
                    border: 1px solid var(--color-purple-dark);
                    border-radius: 50%;
                    width: 64px;
                    height: 64px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a {
                        height: 24px;

                        svg {
                            path {
                                fill: var(--color-purple-dark);
                            }
                        }
                    }
                }
            }
        }
    }

    img {
        display: block;
        max-width: 632px;
        max-height: 590px;
        width: auto;
        height: auto;
        z-index: -1;
    }
}

.message_wrapper {
    position: relative;

    .title_wrapper {
        width: 60%;
    }

    .message_content {
        padding: 24px 0;
        font-size: 22px;
        width: 42%;

        .buttons {
            display: flex;
            gap: 32px;
            padding: 32px 0;

            button {
                width: 330px;
                background-color: var(--color-secondary-light);
                border: 0;
                color: var(--color-white);
                border-radius: 8px;
                padding: 16px 0;
                font-weight: 700;
                font-size: 16px;

                &.iddle {
                    background-color: var(--color-white);
                    color: var(--color-purple-dark);
                    border: 2px solid var(--color-purple-dark);
                }
            }
        }

        .links {
            .links_row {
                display: flex;
                gap: 16px;
                padding-top: 32px;

                .icon_container {
                    border: 1px solid var(--color-purple-dark);
                    border-radius: 50%;
                    width: 64px;
                    height: 64px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a {
                        height: 24px;

                        svg {
                            path {
                                fill: var(--color-purple-dark);
                            }
                        }
                    }
                }
            }
        }
    }

    img {
        position: absolute;
        right: 30px;
        bottom: 0px;
        width: 50%;
    }
}

.no_scroll {
    overflow: hidden;
}

.flex_14gap{
    display: flex;
    gap: 14px;
}

.backbutton {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: 90px;
    color: var(--color-secondary);
    padding: 5px;
    margin-bottom: 40px;

    span {
        color: var(--color-secondary);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
    }

    &:hover {
        background-color: var(--color-label-hover);
    }
}

.form_information {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 40px;

    .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .information_container {
        display: flex;
        justify-content: left;
        gap: 20px;

        .information_level_container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .information_level {
                border-radius: 6px;
                background: var(--color-purple-dark);
                color: var(--color-white);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                width: 45px;
                height: 45px;
                line-height: 50px;
                text-align: center;
            }
        }

        .information_title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
        }

        .information_desc {
            text-align: justify;
        }
    }
}